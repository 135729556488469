import React, {useEffect, useState } from 'react';
import './App.css';
import './firebaseConnect';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { getAuth, onAuthStateChanged, GoogleAuthProvider,  } from 'firebase/auth';
import MentorForm from './MentorForm';
import logo from './xiv.svg';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const uiConfig = {
  signinFlow: 'popup',
  signInOptions: [
    GoogleAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
}

function App() {
  const auth = getAuth();
  const [isSignedIn, setIsSignedIn] = useState(false);
  useEffect(() => {
    const unregisterAuthObserver = onAuthStateChanged(auth, (user: any) => {
      setIsSignedIn(!!user);
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, [auth]);

  
  if (!isSignedIn) {
    return (
      <div>
        <nav className="nav">
          <div className="nav-left">
          <div className="flex">
            <img className="nav-brand" src={logo} alt="" />
            <div className="brand-text">Mentor Tracker</div>
          </div>
          </div>
          <div className="nav-right hide-tablet-down">
            <div className="nav-item">Please Sign In</div>
          </div>
        </nav>
        <div className="container">
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
        </div>
      </div>
    );
  }
  return (
    <div>
      <nav className="nav">
        <div className="nav-left">
          <div className="flex">
            <img className="nav-brand" src={logo} alt="" />
            <div className="brand-text">Mentor Tracker</div>
          </div>
        </div>
        <div className="nav-responsive">
          <div className="nav-right">
            <div className="nav-item hide-tablet-down">
              {auth?.currentUser?.displayName}
            </div>
            <div onClick={() => auth.signOut()} className="nav-item hide-tablet-down logout-button" role="button">
              <i className="d-icon d-exit-to-app"></i>
              <span className="icon-name">Sign Out</span>
            </div>
          </div>
        </div>
      </nav>
      <div className="container">
        <div className="m-t-20">
          <MentorForm />
        </div>
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
}

export default App;
