import { getDatabase, ref, push, set } from "firebase/database";
import type { FormData } from './MentorForm';

async function writeRoulette(data: FormData) {
    const db = getDatabase();
    const postListRef = ref(db, 'mentorRoulette');
    const newPostRef = push(postListRef);
    return set(newPostRef, data);
}

export default writeRoulette;